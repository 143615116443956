import { ReactNode } from 'react';
import { Text } from 'components/Typography';
import { cn } from 'utils/cn';

interface CardProps {
  image: string;
  imageMobile?: string;
  title: ReactNode;
  description: string;
  imagePosition?: 'left' | 'right';
}
export const Card = ({
  title,
  image,
  imageMobile,
  description,
  imagePosition = 'left',
}: CardProps) => {
  return (
    <div className="flex items-center md:h-[416px] drop-shadow-lg">
      <div className="flex flex-row gap-3 md:gap-14 s:h-[228px] max-s:p-2 justify-center items-center bg-white rounded-3xl">
        {imagePosition === 'left' && (
          <div className="w-full max-w-[212px] md:w-1/2 flex justify-center mb-4 md:mb-0">
            <picture>
              <source media="(max-width: 700px)" srcSet={imageMobile} />
              <img
                src={image}
                alt={title?.toString()}
                loading="lazy"
                width={212}
                height={416}
              />
            </picture>
          </div>
        )}

        <div
          className={cn(
            'w-full md:w-1/2 py-1 ',
            imagePosition === 'left' ? 'pr-2.5' : 'pl-2.5'
          )}
        >
          <h3 className="text-xs md:text-2xl text-black font-semibold mb-4">
            {title}
          </h3>
          <Text type="small">{description}</Text>
        </div>

        {imagePosition === 'right' && (
          <div className="w-full max-w-[212px] md:w-1/2 flex justify-center mb-4 md:mb-0">
            <picture>
              <source media="(max-width: 700px)" srcSet={imageMobile} />
              <img
                src={image}
                alt={title?.toString()}
                loading="lazy"
                width={212}
                height={416}
              />
            </picture>
          </div>
        )}
      </div>
    </div>
  );
};
