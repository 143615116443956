import { ReactNode } from 'react';
import { cn } from 'utils/cn';

interface TextProps {
  as?: 'p' | 'span' | 'div' | 'a';
  type?: 'standard' | 'B1' | 'Link1' | 'small' | 'Link2';
  className?: string;
  children: ReactNode;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

const typeStyles = {
  standard: 'text-xs md:text-base md:leading-20 font-normal text-black-80',
  small: 'text-xs leading-12 md:text-xs font-medium text-black-80',
  B1: 'text-sm leading-17 font-bold text-black',
  Link1: 'text-xs font-medium text-black',
  Link2: 'text-xs leading-14 text-black-80',
};

export const Text = ({
  as: Component = 'p',
  type = 'standard',
  href,
  target = '_blank',
  className,
  children,
}: TextProps) => {
  if (Component === 'a') {
    return (
      <a
        href={href}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        className={cn(typeStyles[type], className, 'hover:underline')}
      >
        {children}
      </a>
    );
  }

  return (
    <Component className={cn(typeStyles[type], className)}>
      {children}
    </Component>
  );
};
