import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { cn } from 'utils/cn';

import { ArrowUpRightIcon } from 'assets/icons';

const links = [
  { name: 'home', path: '/' },
  { name: 'product', path: '/product' },
  { name: 'about-us', path: '/about-us' },
];

export const MobileMenu = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <nav className="absolute top-[4.75rem] left-0 w-full z-40">
      <ul className="flex flex-col items-start gap-8 py-8 px-8">
        {links.map((link) => (
          <li
            key={link.name}
            className="w-full flex items-center h-10 justify-between"
          >
            <NavLink
              to={link.path}
              onClick={onClose}
              className={({ isActive }) =>
                isActive
                  ? 'w-full text-[21px] leading-26 font-bold text-black'
                  : 'w-full text-[20px] leading-24 font-semibold text-black-80'
              }
            >
              {({ isActive }) => (
                <span className="flex items-center">
                  {t(`header.${link.name}`)}
                </span>
              )}
            </NavLink>
          </li>
        ))}
        <li className="flex items-center h-10 w-28">
          <NavLink
            to="/contact"
            onClick={onClose}
            className={({ isActive }) =>
              isActive ? 'text-base font-bold text-blue underline' : 'flex'
            }
          >
            {({ isActive }) => (
              <Button
                className={cn(
                  'text-base font-semibold w-full',
                  isActive && 'bg-blue-gradient decoration-white underline'
                )}
                isGradientStyled={false}
                text={t('header.contact')}
                icon={<ArrowUpRightIcon />}
              />
            )}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
