import { Link } from 'react-router-dom';
import { Accordion, AccordionItem } from 'components/Accordion';
import {
  Tag,
  Card,
  Button,
  PartnerCarousel,
  TeamMembers,
  ContactUsBanner,
} from 'components';
import { Text, Heading } from 'components/Typography';
import { changeText } from 'utils/changeText';
import { useLazyBackground } from 'hooks/useLazyBackground';
import { useTranslation } from 'react-i18next';

import {
  homeTitleBannerBackground,
  homeMainBackground,
  contactBannerBackground,
} from 'assets/images/backgrounds';

import {
  iphoneLocation,
  iphoneLogbook,
  iphoneWarning,
  iphoneLocationMobile,
  iphoneLogbookMobile,
  iphoneWarningMobile,
  wavyProductPl,
  wavyProductMobilePl,
  wavyProductTabletPl,
  wavyProductMobileEn,
  wavyProductTabletEn,
  wavyProductEn,
  diverImage,
} from 'assets/images/homepage';

import {
  LocationIcon,
  LogbookIcon,
  MapIcon,
  WarningIcon,
} from 'assets/icons/homepage';

import {
  ScrollDownIcon,
  ScrollUpIcon,
  ArrowRightIcon,
  WaveIcon,
} from 'assets/icons';

const productsList = [
  {
    icon: LocationIcon,
    title: 'homepage.product_subtitle_locator',
    description: 'homepage.product_description_locator',
  },
  {
    icon: LogbookIcon,
    title: 'homepage.product_subtitle_logbook',
    description: 'homepage.product_description_logbook',
  },
  {
    icon: WarningIcon,
    title: 'homepage.product_subtitle_warning',
    description: 'homepage.product_description_warning',
  },
  {
    icon: MapIcon,
    title: 'homepage.product_subtitle_maps',
    description: 'homepage.product_description_maps',
  },
] as const;

const homepageQuestions = [
  { question: 'homepage.faq_question_1', answer: 'homepage.faq_answer_1' },
  { question: 'homepage.faq_question_2', answer: 'homepage.faq_answer_2' },
  { question: 'homepage.faq_question_3', answer: 'homepage.faq_answer_3' },
  { question: 'homepage.faq_question_4', answer: 'homepage.faq_answer_4' },
  { question: 'homepage.faq_question_5', answer: 'homepage.faq_answer_5' },
] as const;

export const Homepage = () => {
  const { t, i18n } = useTranslation();
  useLazyBackground('bg-cover', homeMainBackground);

  const productImage =
    i18n.language === 'en'
      ? {
          mobile: wavyProductMobileEn,
          tablet: wavyProductTabletEn,
          desktop: wavyProductEn,
        }
      : {
          mobile: wavyProductMobilePl,
          tablet: wavyProductTabletPl,
          desktop: wavyProductPl,
        };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${homeMainBackground}`,
        }}
        className="overflow-x-hidden bg-cover bg-no-repeat bg-center"
      >
        <div className="container mx-auto p-6">
          <section
            id="introduction"
            className="relative flex flex-col sm:flex-row items-center gap-10"
          >
            <div
              className="absolute inset-0 bg-center bg-no-repeat bg-contain z-10"
              style={{
                backgroundImage: `url(${homeTitleBannerBackground})`,
              }}
            ></div>

            <div className="flex flex-col gap-3">
              <Tag
                className="w-[155px] z-30"
                text={t('homepage.startup_wavy')}
                icon={<WaveIcon />}
              />
              <Heading as="h1" className="text-3xl md:text-5xl font-extrabold">
                {changeText(t('homepage.heading'), [3], 'text-blue')}
              </Heading>
              <Text className="text-xs md:text-base font-normal">
                {changeText(
                  t('homepage.description'),
                  [0, 1, 2],
                  'font-semibold'
                )}
              </Text>
            </div>
            <img
              src={diverImage}
              className="z-20 w-[300px] s:w-[420px] md:w-[600px] desktop:w-[731px]"
              alt={t('homepage.image_alt_diver')}
              loading="lazy"
              width={731}
              height={432}
            />
          </section>
          <div className="flex justify-end">
            <div className="flex flex-col items-center gap-[10px] ">
              <a href="#products" aria-label={t('homepage.scroll_down')}>
                <ScrollDownIcon className="w-[50px] h-[50px] desktop:w-[90px] desktop:h-[90px]" />
              </a>
              <Text type="Link2">{t('homepage.scroll_down')}</Text>
            </div>
          </div>

          <section id="products" className="flex flex-col items-center pt-16">
            <Tag className="mb-3 md:mb-6" text={t('homepage.our_product')} />
            <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">
              {t('homepage.product_title')}
            </Heading>
            <Text className="mb-4 md:mb-8 text-center">
              {changeText(
                t('homepage.product_description'),
                [5, 6, 7, 8],
                'text-blue font-medium'
              )}
            </Text>
            <div className="grid grid-cols-1 md:grid-cols-2 desktop:grid-cols-4 justify-center gap-4 mb-12 md:mb-16">
              {productsList.map(({ icon, title, description }, index) => (
                <div key={index} className="flex flex-col gap-2 w-[220px]">
                  <img
                    src={icon}
                    className="w-[35px]"
                    alt={t(title)}
                    loading="lazy"
                    width={35}
                    height={35}
                  />
                  <Text className="font-semibold">{t(title)}</Text>
                  <div className="border-[1px] text-black-80 w-[190px]" />
                  <Text type="small">{t(description)}</Text>
                </div>
              ))}
            </div>
            <div className="flex max-sm:flex-col-reverse">
              <div className="flex flex-col gap-3 md:gap-6 w-[327px] max-sm:mt-4 sm:justify-end max-s:px-6">
                <Text type="small">
                  {t('homepage.product_device_redirect_description')}
                </Text>
                <Link to="/product">
                  <Button
                    className="font-semibold text-xs md:text-base md:leading-20 p-3 md:py-5 md:px-6 w-[158px] md:w-[216px]"
                    text={t('homepage.product_device_redirect_button')}
                    icon={<ArrowRightIcon />}
                  />
                </Link>
              </div>
              <picture className="max-s:px-6">
                <source
                  media="(max-width: 380px)"
                  srcSet={productImage.mobile}
                />
                <source
                  media="(max-width: 640px)"
                  srcSet={productImage.tablet}
                />
                <img
                  className="w-[553px]"
                  src={productImage.desktop}
                  alt={t('homepage.product_device_image_description')}
                  loading="lazy"
                  width={535}
                  height={336}
                />
              </picture>
            </div>
          </section>
          {/*TODO: Section to be commented out when app will be ready on stores*/}
          {/*<section*/}
          {/*  id="application"*/}
          {/*  className="flex flex-col items-center pt-16"*/}
          {/*>*/}
          {/*  <Tag*/}
          {/*    className="mb-3 md:mb-6"*/}
          {/*    text={t('homepage.app_tag')}*/}
          {/*  />*/}
          {/*  <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">*/}
          {/*    {t('homepage.app_title')}*/}
          {/*  </Heading>*/}
          {/*  <Text className="text-center">{t('homepage.app_description')}</Text>*/}
          {/*  <Text className="mt-4 md:mt-8">{t('homepage.app_download')}</Text>*/}
          {/*  <div className="flex gap-2 mt-4">*/}
          {/*    <Button*/}
          {/*      text={t('homepage.app_apple_store')}*/}
          {/*      iconLeft={*/}
          {/*        <img*/}
          {/*          src={AppleLogo}*/}
          {/*          className="w-5 h-5"*/}
          {/*          alt={t('homepage.app_apple_store')}*/}
          {/*        />*/}
          {/*      }*/}
          {/*    ></Button>*/}
          {/*    <Button*/}
          {/*      text={t('homepage.app_google_play')}*/}
          {/*      iconLeft={*/}
          {/*        <img*/}
          {/*          src={GooglePlayLogo}*/}
          {/*          className="w-5 h-5"*/}
          {/*          alt={t('homepage.app_google_play')}*/}
          {/*        />*/}
          {/*      }*/}
          {/*    ></Button>*/}
          {/*  </div>*/}
          {/*</section>*/}
          <section
            id="features"
            className="flex flex-col mt-12 mb-16 md:mb-0 s:mt-32 gap-12 s:gap-44 450:gap-56 500:gap-52 md:gap-4"
          >
            <Card
              image={iphoneLocation}
              imageMobile={iphoneLocationMobile}
              title={changeText(
                t('homepage.card_subtitle_preview'),
                [5],
                'uppercase text-blue-100'
              )}
              description={t('homepage.card_description_preview')}
            />
            <Card
              image={iphoneLogbook}
              imageMobile={iphoneLogbookMobile}
              imagePosition="right"
              title={t('homepage.card_subtitle_logbook')}
              description={t('homepage.card_description_logbook')}
            />
            <Card
              image={iphoneWarning}
              imageMobile={iphoneWarningMobile}
              title={changeText(
                t('homepage.card_subtitle_warning'),
                [1],
                'text-yellow-100'
              )}
              description={t('homepage.card_description_warning')}
            />
          </section>
        </div>
      </div>
      <section
        id="team"
        className="flex flex-col items-center container mx-auto mt-0 350:mt-8 500:my-16 p-6"
      >
        <Tag className="mb-3" text={t('homepage.team_tag')} />
        <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">
          {t('homepage.team_title')}
        </Heading>
        <Text className="text-center mb-4 md:mb-8">
          {t('homepage.team_description')}
        </Text>
        <TeamMembers />
      </section>
      <section
        id="partners"
        className="flex flex-col container items-center mx-auto my-16 p-6"
      >
        <Tag className="mb-3" text={t('homepage.partners_tag')} />
        <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">
          {t('homepage.partners_title')}
        </Heading>
        <PartnerCarousel />
      </section>
      <section className="flex flex-col items-center p-6 container mx-auto mb-[100px]">
        <Tag className="mb-3" text={t('homepage.faq_tag')} />
        <Heading as="h2" className="mb-4 md:mb-8 uppercase">
          {t('homepage.faq_title')}
        </Heading>
        <Text className="max-w-[350px] md:max-w-[800px] mb-8 md:mb-16 text-center">
          {t('homepage.faq_description')}
        </Text>
        <Accordion className="w-full flex flex-col gap-3">
          {homepageQuestions.map(({ question, answer }, index) => (
            <AccordionItem key={index} title={t(question)}>
              <p className="ml-[32px] md:ml-[42px]">{t(answer)}</p>
            </AccordionItem>
          ))}
        </Accordion>
      </section>
      <div
        style={{
          backgroundImage: `url(${contactBannerBackground}`,
        }}
        className="max-sm:mt-6 max-sm:pt-6"
      >
        <section className="flex items-end container mx-auto p-6">
          <div className="hidden md:flex justify-end">
            <a
              className="md:scale-50 desktop:scale-100"
              href="#introduction"
              aria-label={t('homepage.scroll_up')}
            >
              <ScrollUpIcon />
            </a>
          </div>
          <ContactUsBanner />
        </section>
      </div>
    </>
  );
};
