import { Text } from './Typography';
import { useTranslation } from 'react-i18next';

import { SmallLinkedinIcon, SmallEmailIcon } from 'assets/icons/contact';

import {
  andrzejczakPhoto,
  wujekPhoto,
  perydzenskiPhoto,
  kanarPhoto,
  chojnackiPhoto,
  sadowskiPhoto,
  kozlowskiPhoto,
} from 'assets/images/team';

interface TeamMemberProps {
  photo: string;
  name: string;
  position: string;
  description: string;
  alternative_description: string;
  linkedin: string;
  email: string;
  tag?: string;
  isAboutUs: boolean;
}

const teamMembersList = [
  {
    photo: andrzejczakPhoto,
    name: 'Michał Andrzejczak',
    position: 'Cloud, Backend dev',
    description: 'homepage.team_member_andrzejczak_description',
    alternative_description: 'about.team_andrzejczak_job_description',
    linkedin: 'https://www.linkedin.com/in/michal-andrzejczak/',
    email: '',
    tag: '',
  },
  {
    photo: wujekPhoto,
    name: 'Jakub Wujek',
    position: 'CEO',
    description: 'homepage.team_member_wujek_description',
    alternative_description: 'about.team_wujek_job_description',
    linkedin: 'https://www.linkedin.com/in/jakub-wujek/',
    email: 'jakub.wujek@wavydevice.com',
    tag: 'Diver',
  },
  {
    photo: perydzenskiPhoto,
    name: 'Damian Perydzeński',
    position: 'CTO',
    description: 'homepage.team_member_perydzenski_description',
    alternative_description: 'about.team_pery_job_description',
    linkedin: 'https://www.linkedin.com/in/damian-perydze%C5%84ski/',
    email: 'damian.perydzenki@wavydevice.com',
    tag: 'Diving instructor',
  },
  {
    photo: kanarPhoto,
    name: 'Radosław Kanar',
    position: 'Electronics Developer',
    description: 'homepage.team_member_kanar_description',
    alternative_description: 'about.team_kanar_job_description',
    linkedin: 'https://www.linkedin.com/in/rados%C5%82aw-kanar-50b403168/',
    email: '',
    tag: '',
  },
  {
    photo: chojnackiPhoto,
    name: 'Jakub Chojnacki',
    position: 'AI Architect',
    description: 'homepage.team_member_chojnacki_description',
    alternative_description: 'about.team_chojnacki_job_description',
    linkedin: 'https://www.linkedin.com/in/jakub-chojnacki-machine-learning/',
    email: '',
    tag: '',
  },
] as const;

const supportMembers = [
  {
    photo: sadowskiPhoto,
    name: 'Maciej Sadowski',
    position: 'Start-up Advisor I Foundrising expert',
    description: '',
    alternative_description: 'about.team_sadowski_job_description',
    linkedin: 'https://www.linkedin.com/in/maciek-sadowski',
    email: '',
    tag: '',
  },
  {
    photo: kozlowskiPhoto,
    name: 'Rafał Kozłowski',
    position: 'Lawyer \n Transaction Law',
    description: '',
    alternative_description: 'about.team_kozlowski_job_description',
    linkedin: 'https://pl.linkedin.com/in/rafkozlowski',
    email: '',
    tag: '',
  },
] as const;

const TeamMember = ({
  isAboutUs,
  photo,
  name,
  position,
  description,
  alternative_description,
  linkedin,
  email,
  tag,
}: TeamMemberProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col py-6 px-5 items-center w-[175px] md:w-[256px]">
      <div className="relative">
        <img
          src={photo}
          alt={name}
          className="h-[100px] w-[100px] md:h-[130px] md:w-[130px] mb-3 md:mb-6"
          loading="lazy"
          width={130}
          height={130}
        />
        {tag && (
          <div className="absolute bottom-0 md:bottom-3 left-1/2 transform -translate-x-1/2 py-[6px] px-3 bg-black-80 text-white text-[10px] leading-12 rounded-2xl text-center">
            {tag}
          </div>
        )}
      </div>
      <Text className="font-semibold text-center mb-1">{name}</Text>
      <Text
        type="small"
        className="font-bold text-blue-100 mb-2 md:mb-4 text-center"
      >
        {position}
      </Text>
      <Text type="small" className="text-center mb-2 md:mb-4">
        {isAboutUs ? t(alternative_description) : t(description)}
      </Text>
      <div className="flex gap-2">
        {linkedin && (
          <a
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${name} linkedin`}
          >
            <SmallLinkedinIcon />
          </a>
        )}
        {email && (
          <a href={`mailto:${email}`} aria-label={`${name} email`}>
            <SmallEmailIcon />
          </a>
        )}
      </div>
    </div>
  );
};

const renderTeamMembers = (
  members: typeof teamMembersList | typeof supportMembers,
  isAboutUs: boolean
) =>
  members.map((member, index) => (
    <TeamMember
      isAboutUs={isAboutUs}
      key={index}
      photo={member.photo}
      name={member.name}
      position={member.position}
      description={member.description}
      alternative_description={member.alternative_description}
      linkedin={member.linkedin}
      email={member.email}
      tag={member.tag}
    />
  ));

export const TeamMembers = ({ isAboutUs = false }: { isAboutUs?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      {isAboutUs ? (
        <>
          <Text className="text-blue-100 uppercase font-bold">
            {t('about.team_main_title')}
          </Text>
          <div className="flex flex-wrap justify-center gap-2 mb-10 md:mb-8">
            {renderTeamMembers(teamMembersList, isAboutUs)}
          </div>
          <Text className="text-blue-100 uppercase font-bold">
            {t('about.team_mentoring_title')}
          </Text>
          <div className="flex flex-wrap justify-center gap-2">
            {renderTeamMembers(supportMembers, isAboutUs)}
          </div>
        </>
      ) : (
        <div className="flex flex-wrap justify-center gap-2">
          {renderTeamMembers(teamMembersList, isAboutUs)}
        </div>
      )}
    </div>
  );
};
