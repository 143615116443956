import { ReactNode, RefObject } from 'react';
import useGradientBorder from 'hooks/useGradientBorder';
import { cn } from 'utils/cn';
import useWindowWidth from 'hooks/useWindowWidth';
import 'styles/GradientStyles.css';

interface ButtonProps {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: ReactNode;
  iconLeft?: ReactNode;
  className?: string;
  isGradientStyled?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button = ({
  text,
  icon,
  iconLeft,
  type = 'button',
  className,
  disabled = false,
  isGradientStyled = true,
  onClick,
}: ButtonProps) => {
  const { elementRef, gradientStyle } = useGradientBorder();
  const windowWidth = useWindowWidth();

  return (
    <button
      ref={elementRef as RefObject<HTMLButtonElement>}
      className={cn(
        'py-2 px-5 rounded-3xl bg-black text-white flex items-center justify-center',
        isGradientStyled &&
          windowWidth >= 700 &&
          'transition-colors duration-300 ease-in-out button-gradient-border',
        disabled && 'bg-black-60 cursor-not-allowed',
        className
      )}
      type={type}
      disabled={disabled}
      style={gradientStyle}
      onClick={onClick}
    >
      {iconLeft && <span className="mr-2">{iconLeft}</span>}
      {text}
      {icon && <span className="ml-2">{icon}</span>}
    </button>
  );
};
