import { useState, ReactNode, RefObject } from 'react';
import { cn } from 'utils/cn';
import useGradientBorder from 'hooks/useGradientBorder';
import useWindowWidth from 'hooks/useWindowWidth';
import 'styles/GradientStyles.css';

import { ArrowDownAccordionIcon, ArrowUpIcon } from 'assets/icons';

interface AccordionItemProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export const AccordionItem = ({
  children,
  title,
  className,
}: AccordionItemProps) => {
  const { elementRef, gradientStyle } = useGradientBorder();
  const windowWidth = useWindowWidth();

  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => setIsOpen(!isOpen);

  const headingId = `accordion-header-${title.replace(/\s+/g, '-').toLowerCase()}`;
  const panelId = `accordion-panel-${title.replace(/\s+/g, '-').toLowerCase()}`;

  return (
    <div
      ref={elementRef as RefObject<HTMLDivElement>}
      className={cn(
        'rounded-[10px] border border-blue-60 bg-white py-5 md:pb-6 px-5 md:px-[3.375rem] cursor-pointer',
        className,
        windowWidth >= 700 &&
          'transition-colors duration-300 ease-in-out accordion-gradient-border '
      )}
      style={gradientStyle}
      onClick={toggleAccordion}
    >
      <div className="flex items-center">
        <span
          className="mr-5 md:mr-8 transform transition-transform duration-300"
          aria-hidden="true"
        >
          {isOpen ? <ArrowUpIcon /> : <ArrowDownAccordionIcon />}
        </span>
        <h3 id={headingId} className="flex text-lg font-semibold">
          <button
            aria-expanded={isOpen}
            aria-controls={panelId}
            className="focus:outline-none text-xs leading-14 md:text-base md:leading-20 font-medium text-black"
            onClick={toggleAccordion}
          >
            {title}
          </button>
        </h3>
      </div>
      {isOpen && (
        <div
          id={panelId}
          role="region"
          aria-labelledby={headingId}
          className="pt-3 px-3 text-xs font-normal"
        >
          {children}
        </div>
      )}
    </div>
  );
};
