import { ReactNode, RefObject } from 'react';
import { Text } from './Typography';
import useGradientBorder from 'hooks/useGradientBorder';
import useWindowWidth from 'hooks/useWindowWidth';
import { cn } from 'utils/cn';

interface TileProps {
  icon: ReactNode;
  title: string;
  description: string;
  isGradientStyled?: boolean;
}

export const Tile = ({
  icon,
  title,
  description,
  isGradientStyled = true,
}: TileProps) => {
  const { elementRef, gradientStyle } = useGradientBorder();
  const windowWidth = useWindowWidth();

  return (
    <div
      ref={elementRef as RefObject<HTMLDivElement>}
      style={gradientStyle}
      className={cn(
        'w-[220px] h-[220px] md:w-[275px] md:h-[275px] bg-white border border-blue-40 rounded-[20px] py-5 px-3 md:p-[40px] shadow-inner',
        isGradientStyled &&
          windowWidth >= 700 &&
          'transition-colors duration-300 ease-in-out button-gradient-border'
      )}
    >
      <div className="flex flex-col items-center text-center gap-2 md:gap-3">
        <div>{icon}</div>
        <Text className="text-blue-100 font-bold">{title}</Text>
        <Text type="small" className="text-black-80">
          {description}
        </Text>
      </div>
    </div>
  );
};
