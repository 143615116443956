import { TextareaHTMLAttributes, forwardRef } from 'react';
import { cn } from 'utils/cn';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: string;
  className?: string;
  isRequired?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, error, className, isRequired, ...props }, ref) => {
    return (
      <div className={cn('w-full', className)}>
        <label className="ml-2 mb-2 text-xs font-semibold text-black-70">
          {label}
          {isRequired && <span>*</span>}
        </label>
        <div className="relative mt-1">
          <textarea
            ref={ref}
            className={cn(
              'w-full h-[190px] rounded-3xl border border-black-40 px-5 py-[14px] text-xs text-black-80 focus:border-black focus:outline-none focus:ring-black',
              error ? 'border-red focus:border-red focus:ring-red' : ''
            )}
            {...props}
          />
        </div>
        {error && (
          <p className="mt-2 ml-2 text-xs text-xs leading-12 text-red font-medium">
            {error}
          </p>
        )}
      </div>
    );
  }
);
