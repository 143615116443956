export const changeText = (
  text: string,
  indexes: number[],
  className: string
) => {
  const words = text.split(' ');

  return words.map((word, index) => {
    if (indexes.includes(index)) {
      return (
        <span key={index} className={className}>
          {word}{' '}
        </span>
      );
    }
    return `${word} `;
  });
};
