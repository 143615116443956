import { ReactNode } from 'react';
import { cn } from 'utils/cn';
interface HeadingProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: ReactNode;
  className?: string;
}

const headingStyles = {
  h1: 'text-2xl leading-30 md:text-5xl md:leading-58 font-extrabold',
  h2: 'text-2xl leading-30 md:text-5xl md:leading-58 font-extrabold',
  h3: 'text-2xl font-medium',
  h4: 'text-xl font-medium',
  h5: 'text-lg font-medium',
  h6: 'text-base font-medium',
};

export const Heading = ({ as, className, children }: HeadingProps) => {
  const Tag = as;

  return <Tag className={cn(headingStyles[as], className)}>{children}</Tag>;
};
