import { Text } from 'components/Typography';
import { cn } from 'utils/cn';
import { SuccessIcon, ErrorIcon } from 'assets/icons/contact';

interface StatusScreenProps {
  title: string;
  message: string;
  details?: string;
  type: 'success' | 'error';
}
export const StatusScreen = ({
  title,
  message,
  details,
  type,
}: StatusScreenProps) => (
  <div className="flex flex-col gap-4 md:gap-6">
    <div className="flex justify-center">
      {type === 'success' ? (
        <SuccessIcon className="justify-center w-[50px] h-[50px] md:w-[70px] md:h-[70px]" />
      ) : (
        <ErrorIcon className="justify-center w-[50px] h-[50px] md:w-[70px]" />
      )}
    </div>
    <Text
      className={cn(
        'text-2xl font-bold text-center',
        type === 'success' ? 'text-blue' : 'text-red'
      )}
    >
      {title}
    </Text>
    <Text className="text-center whitespace-pre-wrap">{message}</Text>
    {details && <Text className="text-center">{details}</Text>}
  </div>
);
