import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const SEO = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en';

  return (
    <Helmet>
      <html lang={language} />
      <title>{t('seo.title')}</title>
      <meta name="description" content={t('seo.description')} />
      <meta name="keywords" content={t('seo.keywords')} />
      <meta name="author" content="Wavy Device" />
      <link rel="canonical" href={t('seo.canonical')} />

      {/* Open Graph tags */}
      <meta property="og:title" content={t('seo.ogTitle')} />
      <meta property="og:description" content={t('seo.ogDescription')} />
      <meta property="og:url" content={t('seo.ogUrl')} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};
