import { InputHTMLAttributes, forwardRef, ReactNode } from 'react';
import { cn } from 'utils/cn';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  error?: string;
  className?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, error, className, ...props }, ref) => {
    return (
      <div className={cn('flex flex-col space-y-2', className)}>
        <label className="flex max-s:items-start items-center gap-2 text-xs leading-12 font-medium text-black-70">
          <input
            ref={ref}
            type="checkbox"
            className={cn(
              'h-4 w-4 rounded border border-black-40 text-blue focus:ring-black focus:border-black',
              error ? 'border-red focus:border-red focus:ring-red' : ''
            )}
            {...props}
          />
          <span>
            {label}
            {props.required && <span>*</span>}
          </span>
        </label>
        {error && (
          <p className="mt-2 ml-6 text-xs text-xs leading-12 text-red font-medium">
            {error}
          </p>
        )}
      </div>
    );
  }
);
