import { Link } from 'react-router-dom';
import { Button, Tag } from 'components';
import { useTranslation } from 'react-i18next';

import phone from 'assets/images/iphone-wavy.webp';

import {
  WavyLogoCrop30x30,
  HandWaveEmojiIcon38x38,
  SmileyFaceEmojiIcon38x38,
  NewMessageIcon,
  MessageEmojiIcon,
} from 'assets/icons/contact';

import { ArrowRightIcon } from 'assets/icons';

export const ContactUsBanner = () => {
  const { t } = useTranslation();

  return (
    <div className="flex max-sm:relative px-2 360:px-3 400:px-4 max-sm:container mx-auto sm:items-center sm:gap-9">
      <div className="md:relative max-sm:absolute max-sm:max-sm:top-[-65px] max-sm:max-sm:z-10 drop-shadow">
        <div className="relative flex flex-col w-[290px] sm:w-[500px] desktop:w-[724px] bg-white rounded-t-[20px] rounded-bl-[20px] py-4 px-5 gap-2 mb-4">
          <NewMessageIcon className="absolute right-0 top-[-28px] md:hidden" />
          <Tag
            className="max-md:hidden md:absolute top-[-30px] left-[30%]"
            text={t('contact-banner.tag')}
            icon={<MessageEmojiIcon />}
          />
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <WavyLogoCrop30x30 className="w-[20px] h-[20px] desktop:w-[30px] desktop:h-[30px]" />
              <span className="text-xs leading-12 md:text-base md:leading-20 font-semibold">
                {t('contact-banner.addressee')}
              </span>
            </div>
            <span className="text-black-70 text-xs leading-12 md:text-base md:leading-20">
              {t('contact-banner.time')}
            </span>
          </div>
          <p className="flex gap-1 md:gap-2 text-xs font-bold desktop:text-[32px] desktop:leading-39">
            {t('contact-banner.introduction')}{' '}
            <HandWaveEmojiIcon38x38 className="w-[18px] h-[18px] desktop:w-[38px] desktop:h-[38px]" />
            {t('contact-banner.content')}
            <SmileyFaceEmojiIcon38x38 className="w-[18px] h-[18px] desktop:w-[38px] desktop:h-[38px]" />
          </p>
          <span className="text-xs leading-12 md:text-xs">
            {t('contact-banner.message_1')}
          </span>
          <span className="text-xs leading-12 md:text-xs">
            {t('contact-banner.message_2')}
          </span>
        </div>
        <Link to="/contact" className="block">
          <Button
            className="md:absolute md:bottom-[-16px] desktop:bottom-[-24px] md:right-[25px] font-semibold text-xs leading-12 desktop:text-base desktop:leading-20 p-3 desktop:py-5 desktop:px-6 z-10"
            text={t('contact-banner.button')}
            isGradientStyled={false}
            icon={<ArrowRightIcon />}
          />
        </Link>
      </div>
      <div className="w-full flex max-sm:justify-end">
        <img
          className="w-[152px] md:w-[252px] desktop:w-[352px]"
          src={phone}
          alt={t('contact-banner.image_alt_phone')}
          loading="lazy"
          width={352}
          height={692}
        />
      </div>
    </div>
  );
};
