import { ReactNode, CSSProperties } from 'react';

interface AccordionProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Accordion = ({ children, className, style }: AccordionProps) => {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};
