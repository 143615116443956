import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStickyHeader } from 'hooks/useStickyHeader';
import { Button, LanguageSwitcher, MobileMenu } from 'components';
import { cn } from 'utils/cn';

import { mobileHeaderBackground } from 'assets/images/backgrounds';

import {
  ArrowUpRightIcon,
  WavyLogo,
  HamburgerIcon,
  CloseIcon,
} from 'assets/icons';

const links = [
  { name: 'home', path: '/' },
  { name: 'product', path: '/product' },
  { name: 'about-us', path: '/about-us' },
] as const;

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isSticky = useStickyHeader();
  const { t } = useTranslation();

  return (
    <header className={cn('bg-white', isSticky && 'sticky top-0 z-50')}>
      <div
        className={cn(
          'py-5 flex h-[4.75rem] items-center desktop:mx-auto desktop:max-w-[90rem] justify-between'
        )}
      >
        <div className="ml-5 md:ml-[5.625rem] scale-125">
          <WavyLogo />
        </div>
        {isMobileMenuOpen && (
          <div
            className="fixed inset-0 top-[4.75rem] bg-white z-40 bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${mobileHeaderBackground})`,
            }}
          />
        )}
        {isMobileMenuOpen ? (
          <MobileMenu onClose={() => setIsMobileMenuOpen(false)} />
        ) : (
          <nav className="container mx-auto justify-center">
            <ul className="flex justify-center gap-8 items-center max-sm:hidden">
              {links.map((link) => (
                <li key={link.name} className="flex items-center h-10">
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-base font-bold text-blue-100 underline'
                        : 'text-base font-semibold text-black'
                    }
                  >
                    {t(`header.${link.name}`)}
                  </NavLink>
                </li>
              ))}
              <li className="flex items-center h-10 w-28">
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive
                      ? 'text-base font-bold text-blue-100 underline'
                      : 'flex'
                  }
                >
                  {({ isActive }) => (
                    <Button
                      className={cn(
                        'text-base font-semibold w-full',
                        isActive &&
                          'bg-blue-gradient decoration-white underline'
                      )}
                      isGradientStyled={false}
                      text={t('header.contact')}
                      icon={<ArrowUpRightIcon />}
                    />
                  )}
                </NavLink>
              </li>
            </ul>
          </nav>
        )}
        {!isMobileMenuOpen && <LanguageSwitcher />}
        <button
          className="h-[1.5rem] w-[1.5rem] mr-5 sm:hidden"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label={
            isMobileMenuOpen
              ? t('header.aria_hamburger_is_open')
              : t('header.aria_hamburger_is_closed')
          }
        >
          {!isMobileMenuOpen ? <HamburgerIcon /> : <CloseIcon />}
        </button>
      </div>
    </header>
  );
};
