import { useEffect } from 'react';

export const useLazyBackground = (
  selector: string,
  backgroundImage: string
) => {
  useEffect(() => {
    const lazyBackground = document.querySelector(
      selector
    ) as HTMLElement | null;

    if (!lazyBackground) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          lazyBackground.style.backgroundImage = `url(${backgroundImage})`;
          observer.unobserve(lazyBackground);
        }
      });
    });

    observer.observe(lazyBackground);

    return () => {
      observer.disconnect();
    };
  }, [selector, backgroundImage]);
};
