import { useEffect, useRef, CSSProperties } from 'react';

const useGradientBorder = () => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;

        const halfWidth = rect.width / 2;
        const gradientDirection = x < halfWidth ? '90deg' : '270deg';

        elementRef.current.style.setProperty(
          '--gradient-direction',
          gradientDirection
        );
      }
    };

    const element = elementRef.current;
    element?.addEventListener('mousemove', handleMouseMove);

    return () => {
      element?.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return {
    elementRef,
    gradientStyle: {
      '--gradient-direction': '90deg',
    } as CSSProperties,
  };
};

export default useGradientBorder;
