import { cn } from 'utils/cn';

interface DividerProps {
  className?: string;
  isVertical?: boolean;
}

const Divider = ({ className, isVertical = false }: DividerProps) => {
  const dividerTypeClass = !isVertical
    ? 'h-[1px] my-4 md:my-6 w-full'
    : 'w-[1px] mx-4 md:mx-6 h-full';

  return (
    <div
      className={cn('border border-black-40', dividerTypeClass, className)}
    ></div>
  );
};

export { Divider };
