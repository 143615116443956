import { NavLink } from 'react-router-dom';
import { Text } from 'components/Typography/Text';
import { useTranslation } from 'react-i18next';

import { FacebookIcon, WavyLogo, LinkedinInIcon } from 'assets/icons';

import privacyPolicyPl from 'assets/files/privacy_policy_wavy_pl.pdf';
import privacyPolicyEn from 'assets/files/privacy_policy_wavy_en.pdf';

const links = [
  { name: 'footer.homepage', path: '/' },
  { name: 'footer.about-us', path: '/about-us' },
  { name: 'footer.product', path: '/product' },
  { name: 'footer.contact', path: '/contact' },
] as const;

export const Footer = () => {
  const { t, i18n } = useTranslation();

  const privacyPolicyLinks: Record<string, string> = {
    en: privacyPolicyEn,
    pl: privacyPolicyPl,
  };

  const privacyPolicyLink =
    privacyPolicyLinks[i18n.language] || privacyPolicyLinks.pl;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="bg-white py-10 md:py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
            <div className="mb-3">
              <WavyLogo />
            </div>
            <Text type="Link1">{t('footer.say_hello')}</Text>
            <div className="flex mt-2 space-x-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/wavydevice"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/wavy-device/"
                aria-label="Linked-In"
              >
                <LinkedinInIcon />
              </a>
            </div>
          </div>
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
            <Text className="mb-3" type="B1">
              {t('footer.company_pages_title')}
            </Text>
            <ul className="space-y-2">
              {links.map(({ name, path }, index) => (
                <li key={index}>
                  <NavLink
                    to={path}
                    className="hover:underline"
                    onClick={scrollToTop}
                  >
                    <Text type="Link1">{t(name)}</Text>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
            <Text className="mb-3" type="B1">
              {t('footer.legal_title')}
            </Text>
            <ul className="space-y-2">
              <li>
                <a
                  href={privacyPolicyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text className="hover:underline" type="Link1">
                    {t('footer.privacy_policy')}
                  </Text>
                </a>
              </li>
              <li>
                <a
                  href={privacyPolicyPl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text className="hover:underline" type="Link1">
                    {t('footer.cookies')}
                  </Text>
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full sm:w-auto">
            <Text className="mb-3" type="B1">
              {t('footer.contact_data_title')}
            </Text>
            <div className="flex flex-col gap-1">
              <Text type="Link1">Jakub Wujek</Text>
              <div className="flex items-center gap-1">
                <Text type="Link1">t:</Text>
                <Text as="a" href="tel:+48694079390" type="Link1">
                  +48 694 079 390
                </Text>
              </div>
              <div className="flex items-center gap-1">
                <Text type="Link1">e:</Text>
                <Text
                  as="a"
                  href="mailto:jakub.wujek@wavydevice.com"
                  type="Link1"
                >
                  jakub.wujek@wavydevice.com
                </Text>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <Text type="Link1" className="mt-4">
                Damian Perydzeński
              </Text>
              <div className="flex items-center gap-1">
                <Text type="Link1">t:</Text>
                <Text as="a" href="tel:+48502084698" type="Link1">
                  +48 502 084 698
                </Text>
              </div>
              <div className="flex items-center gap-1">
                <Text type="Link1">e:</Text>
                <Text
                  as="a"
                  href="mailto:damian.perydzenski@wavydevice.com"
                  type="Link1"
                >
                  damian.perydzenski@wavydevice.com
                </Text>
              </div>
            </div>
          </div>
        </div>
        <Text className="mt-8 md:mt-10 text-center !text-xs">
          Copyright © 2024 all rights reserved Wavy.
        </Text>
      </div>
    </footer>
  );
};
