import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Homepage, Contact, Product, About } from './pages';
import { ScrollToTop } from 'utils/ScrollToTop';
import { Header, Footer, SEO } from 'components';
import './styles/global.css';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <ScrollToTop />
        <SEO />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/product" element={<Product />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
