import { useState, useRef, useEffect, useCallback } from 'react';

export const useStickyHeader = () => {
  const [isSticky, setSticky] = useState<boolean>(false);
  const threshold = 73;
  const lastScrollY = useRef<number>(0);

  const handleScroll = useCallback(() => {
    if (window.scrollY > threshold) {
      if (window.scrollY > lastScrollY.current) {
        // Scrolling down
        setSticky(false);
      } else {
        // Scrolling up
        setSticky(true);
      }
    } else {
      setSticky(false);
    }
    lastScrollY.current = window.scrollY;
  }, [threshold]);

  const handleAnchorClick = useCallback(() => {
    setSticky(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.querySelectorAll('a').forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.querySelectorAll('a').forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, [handleScroll, handleAnchorClick]);

  return isSticky;
};
