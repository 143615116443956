import { Link } from 'react-router-dom';
import { Button, ContactUsBanner, Tag, TeamMembers } from 'components';
import { Heading, Text } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { changeText } from 'utils/changeText';
import { useLazyBackground } from 'hooks/useLazyBackground';

import {
  aboutUsMainBackground,
  contactBannerBackground,
} from 'assets/images/backgrounds';

import {
  wreckDiveMobile,
  wreckDive,
  team,
  wujekDiver,
  peryDiver,
  wavyProduct,
} from 'assets/images/about-us';

import {
  FacebookFilled,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  QuestionMarkIcon,
  ExclamationMarkIcon,
  AcceptanceIcon,
} from 'assets/icons/about';

import { ArrowRightIcon, ScrollDownIcon } from 'assets/icons';

export const About = () => {
  const { t } = useTranslation();

  useLazyBackground('bg-cover', aboutUsMainBackground);

  return (
    <>
      <link rel="preload" href={aboutUsMainBackground} as="image" />
      <div
        style={{
          backgroundImage: `url(${aboutUsMainBackground}`,
        }}
        className="overflow-hidden bg-cover bg-no-repeat bg-top"
      >
        <div className="flex flex-col items-center container mx-auto pt-6 px-6">
          <Tag className="mb-3 md:mb-6" text={t('about.tag')} />
          <Heading as="h1" className="mb-4 md:mb-8 uppercase text-center">
            {t('about.title')}
          </Heading>
          <picture>
            <source media="(max-width: 700px)" srcSet={wreckDiveMobile} />
            <img
              className="max-w-[903px] w-full mb-8 h-auto"
              src={wreckDive}
              alt={t('about.image_alt_wreck_dive')}
              loading="lazy"
              width={903}
              height={350}
            />
          </picture>
          <p className="text-xs leading-14 sm:text-base sm:leading-20 text-center mb-8">
            {t('about.story')}
          </p>
          <div className="flex flex-col gap-3 md:gap-4 items-center">
            <span className="text-xs leading-14 font-semibold sm:text-base sm:leading-20">
              {t('about.social-title')}
            </span>
            <a
              target="_blank"
              href="https://www.facebook.com/wavydevice"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FacebookFilled />
            </a>
          </div>
        </div>
        <div className="flex justify-end container mx-auto">
          <div className="flex flex-col items-center gap-[10px] mr-6">
            <a href="#team" aria-label={t('homepage.scroll_down')}>
              <ScrollDownIcon className="w-[50px] h-[50px] desktop:w-[90px] desktop:h-[90px]" />
            </a>
            <span className="text-xs leading-14 text-black-80">
              {t('homepage.scroll_down')}
            </span>
          </div>
        </div>
        <section
          id="team"
          className="flex flex-col items-center mt-8 container mx-auto"
        >
          <Tag className="mb-3 md:mb-6" text={t('about.team_tag')} />
          <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">
            {t('about.team_title')}
          </Heading>
          <Text className="mb-4 md:mb-8 px-6">
            {t('about.team_description')}
          </Text>
          <TeamMembers isAboutUs={true} />
        </section>
        <section className="flex flex-col items-center mt-8 container mx-auto">
          <Tag className="mb-3 md:mb-6" text={t('about.history_tag')} />
          <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">
            {t('about.history_title')}
          </Heading>
          <div className="flex flex-col md:flex-row gap-6 desktop:gap-16 mb-8 mx-9">
            <div className="flex justify-center">
              <img
                className="max-md:w-[200px] max-md:h-[200px] w-full mb-8"
                src={team}
                alt={t('about.image_alt_team_work')}
                loading="lazy"
                width={300}
                height={300}
              />
            </div>

            <div className="flex flex-col gap-6 md:gap-8">
              <div className="flex gap-3 md:gap-6">
                <div>
                  <OneIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
                </div>
                <div className="flex flex-col gap-2 md:gap-3">
                  <Text className="font-bold text-blue-100 uppercase">
                    {t('about.history_title_point1')}
                  </Text>
                  <Text type="small" className="font-normal text-black">
                    {t('about.history_description_point1')}
                  </Text>
                </div>
              </div>
              <div className="flex gap-3 md:gap-6">
                <div>
                  <TwoIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
                </div>
                <div className="flex flex-col gap-2 md:gap-3">
                  <Text className="font-bold text-blue-100 uppercase">
                    {t('about.history_title_point2')}
                  </Text>
                  <Text type="small" className="font-normal text-black">
                    {t('about.history_description_point2')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row gap-6 desktop:gap-16 mx-9">
            <div className="flex flex-col gap-6 md:gap-8">
              <div className="flex gap-3 md:gap-6">
                <div>
                  <ThreeIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
                </div>
                <div className="flex flex-col gap-2 md:gap-3">
                  <Text className="font-bold text-blue-100 uppercase">
                    {t('about.history_title_point3')}
                  </Text>
                  <Text type="small" className="font-normal text-black">
                    {t('about.history_description_point3')}
                  </Text>
                </div>
              </div>
              <div className="flex gap-3 md:gap-6">
                <div>
                  <FourIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
                </div>
                <div className="flex flex-col gap-2 md:gap-3">
                  <Text className="font-bold text-blue-100 uppercase">
                    {t('about.history_title_point4')}
                  </Text>
                  <Text type="small" className="font-normal text-black">
                    {t('about.history_description_point4')}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                className="max-md:w-[200px] max-md:h-[200px] w-full mb-8"
                src={wavyProduct}
                alt={t('about.image_alt_product')}
                loading="lazy"
                width={300}
                height={300}
              />
            </div>
          </div>
          <Link
            className="hidden md:block mt-6 md:mt-8 mb-8 md:mb-16"
            to="/product"
          >
            <Button
              text={t('about.history_button_product_redirect')}
              icon={<ArrowRightIcon />}
            />
          </Link>
        </section>
        <section className="flex flex-col items-center container mx-auto px-6 md:px-9 mt-8 md:mt-16">
          <Tag className="mb-3 md:mb-6" text={t('about.problem_tag')} />
          <Heading as="h2" className="mb-8 md:mb-14 uppercase text-center">
            {t('about.problem_title')}
          </Heading>
          <div className="flex flex-col-reverse md:flex-row gap-8 md:gap-16">
            <div className="flex flex-col items-center gap-8 md:w-1/2">
              <div className="flex flex-col gap-2 md:gap-3">
                <div className="flex items-center gap-3">
                  <QuestionMarkIcon />
                  <Text className="text-blue-100 font-bold">
                    {t('about.problem_question_title')}
                  </Text>
                </div>
                <Text type="small">
                  {changeText(
                    t('about.problem_question_description'),
                    [10, 11, 12, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
                    'text-blue-100 font-bold'
                  )}
                </Text>
              </div>
              <div className="flex flex-col gap-2 md:gap-3">
                <div className="flex items-center gap-3">
                  <ExclamationMarkIcon />
                  <Text className="text-blue-100 font-bold">
                    {t('about.problem_way_title')}
                  </Text>
                </div>
                <Text type="small">
                  {changeText(
                    t('about.problem_way_description'),
                    [
                      9, 10, 11, 12, 13, 14, 43, 44, 45, 46, 47, 48, 49, 52, 53,
                      54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
                    ],
                    'text-blue-100 font-bold'
                  )}
                </Text>
              </div>
              <div className="flex flex-col gap-2 md:gap-3">
                <div className="flex items-center gap-3">
                  <AcceptanceIcon />
                  <Text className="text-blue-100 font-bold">
                    {t('about.problem_solution_title')}
                  </Text>
                </div>
                <Text type="small">
                  {t('about.problem_solution_description')}
                </Text>
              </div>
            </div>
            <div className="flex flex-col md:w-1/2 justify-center gap-4 md:gap-8">
              <div className="bg-white py-3 pl-4 pr-6 max-w-[459px] rounded-tl-[50px] rounded-tr-[50px] rounded-bl-[50px] flex gap-6">
                <img
                  className="w-[110px] h-[110px]"
                  src={wujekDiver}
                  alt="Jakub Wujek, CEO"
                  loading="lazy"
                  width={110}
                  height={110}
                />
                <div className="flex flex-col gap-2">
                  <Text className="font-bold text-blue-100">
                    Jakub Wujek, CEO
                  </Text>
                  <Text className="!text-xs !font-normal !text-black">
                    {t('about.problem_wujek_mission')}
                  </Text>
                </div>
              </div>
              <div className="bg-white py-3 pl-4 pr-6 max-w-[459px] rounded-tl-[50px] rounded-tr-[50px] rounded-br-[50px] flex gap-6">
                <img
                  className="w-[110px] h-[110px]"
                  src={peryDiver}
                  alt="Damian Perydzeński, CTO"
                  loading="lazy"
                  width={110}
                  height={110}
                />
                <div className="flex flex-col gap-2">
                  <Text className="font-bold text-blue-100">
                    Damian Perydzeński, CTO
                  </Text>
                  <Text className="!text-xs !font-normal !text-black">
                    {t('about.problem_pery_mission')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        style={{
          backgroundImage: `url(${contactBannerBackground}`,
        }}
        className="max-sm:mt-6 max-sm:pt-6"
      >
        <section className="flex items-end container mx-auto pb-6 mt-24">
          <ContactUsBanner />
        </section>
      </div>
    </>
  );
};
