import { ReactNode } from 'react';
import { cn } from '../utils/cn';

interface TagProps {
  text: string;
  icon?: ReactNode;
  className?: string;
}
export const Tag = ({ text, icon, className }: TagProps) => {
  return (
    <div
      className={cn(
        'bg-blue-gradient text-white rounded-3xl flex justify-center items-center',
        icon
          ? 'py-[0.41rem] px-3 md:py-[0.66rem] md:px-5'
          : 'py-[0.375rem] px-3 md:py-2 md:px-5',
        className
      )}
    >
      <span className="font-medium text-xs">{text}</span>
      {icon && <span className="ml-2">{icon}</span>}
    </div>
  );
};
