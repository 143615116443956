import { useState, useRef, useEffect } from 'react';
import { Button, Tag, Divider, StatusScreen, Loader } from 'components';
import { useLazyBackground } from 'hooks/useLazyBackground';
import useWindowWidth from 'hooks/useWindowWidth';

import {
  Heading,
  Input,
  Checkbox,
  Textarea,
  Text,
} from 'components/Typography';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import contactImage from 'assets/images/contact-image.webp';
import contactImageSquare from 'assets/images/contact-image_square.webp';
import {
  contactMainBackground,
  contactMobileMainBackground,
} from 'assets/images/backgrounds';

import {
  PhoneIcon,
  EmailIcon,
  HandWaveEmojiIcon38x38,
} from 'assets/icons/contact';

import { ArrowRightIcon } from 'assets/icons';
import privacyPolicyEn from 'assets/files/privacy_policy_wavy_en.pdf';
import privacyPolicyPl from 'assets/files/privacy_policy_wavy_pl.pdf';

type Step = 'Form' | 'Success' | 'Error';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
  checkbox: boolean;
}

export const Contact = () => {
  const { t, i18n } = useTranslation();
  const windowWidth = useWindowWidth();

  const [step, setStep] = useState<Step>('Form');
  const [isLoading, setIsLoading] = useState(false);

  const [formHeight, setFormHeight] = useState<number>(0);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormData>();

  const backgroundImage =
    windowWidth <= 700 ? contactMobileMainBackground : contactMainBackground;

  useEffect(() => {
    if (imageContainerRef.current) {
      setFormHeight(imageContainerRef.current.offsetHeight);
    }
  }, [step, windowWidth]);

  useLazyBackground('.bg-cover', backgroundImage);

  const privacyPolicyLinks: Record<string, string> = {
    en: privacyPolicyEn,
    pl: privacyPolicyPl,
  };

  const privacyPolicyLink =
    privacyPolicyLinks[i18n.language] || privacyPolicyLinks.pl;

  const onSubmit = async (data: ContactFormData) => {
    setIsLoading(true);
    try {
      const { name, email, message } = data;

      const response = await fetch(`api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (!response.ok) {
        throw new Error('Failed to send data');
      }

      setStep('Success');
    } catch (error) {
      console.error('Error:', error);
      setStep('Error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <link rel="preload" href={contactMainBackground} as="image" />
      <link rel="preload" href={contactMobileMainBackground} as="image" />
      <div
        style={{
          backgroundImage: `url(${backgroundImage}`,
        }}
        className="overflow-x-hidden bg-cover bg-no-repeat bg-center"
      >
        <div className="flex flex-col items-center container mx-auto pt-6 px-6">
          <Tag className="mb-3 md:mb-6" text={t('contact.tag')} />
          <Heading as="h1" className="mb-4 md:mb-8 uppercase text-center">
            {t('contact.title')}
          </Heading>
        </div>

        <div className="flex flex-col md:flex-row-reverse gap-4 md:gap-6 justify-center items-center md:items-end container mx-auto px-6 pb-16">
          <div
            ref={imageContainerRef}
            className="w-full md:!w-1/2 flex flex-col gap-4 items-center h-full flex-1"
          >
            <div className="flex flex-col w-full md:gap-6">
              <picture>
                <source
                  media="(min-width: 900px) and (max-width: 1439px)"
                  srcSet={contactImageSquare}
                />
                <img
                  className="max-w:full h-auto md:h-[583px] desktop:h-auto sm:mx-auto md:mx-0 desktop:mx-auto max-md:mb-8"
                  src={contactImage}
                  loading="lazy"
                  alt={t('contact.image_alt_contact_form')}
                  width={514}
                  height={514}
                />
              </picture>

              <div className="flex gap-3 md:gap-6 flex-wrap md:flex-col py-6 md:py-10 pl-5 md:pl-[50px] rounded-3xl bg-white">
                <div className="flex gap-2 md:gap-6">
                  <EmailIcon />
                  <div className="flex flex-col gap-1 md:gap-2">
                    <Text className="text-black font-semibold">
                      {t('contact.email')}
                    </Text>
                    <Text
                      as="a"
                      href="mailto:info@wavydevice.com"
                      className="text-black"
                    >
                      info@wavydevice.com
                    </Text>
                  </div>
                </div>
                <div className="flex gap-2 md:gap-6">
                  <PhoneIcon />
                  <div className="flex flex-col gap-1 md:gap-2">
                    <Text className="text-black font-semibold">
                      {t('contact.phone')}
                    </Text>
                    <Text as="a" className="text-black" href="tel:+48694079390">
                      +48 694 079 390
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full justify-center md:!w-1/2 h-full flex flex-col gap-4 md:gap-6 py-6 px-5 md:py-10 md:px-[50px] bg-white rounded-3xl container mx-auto flex-1"
            style={
              windowWidth >= 900 ? { minHeight: `${formHeight}px` } : undefined
            }
          >
            {step === 'Form' && !isLoading && (
              <>
                <span className="flex items-center gap-2 md:gap-3 text-black-80 font-bold text-base leading-20 md:text-2xl">
                  {t('contact.form.title')}
                  <HandWaveEmojiIcon38x38 className="w-[26px] h-[26px] desktop:w-[38px] desktop:h-[38px]" />
                </span>
                <div className="flex flex-col">
                  <span className="text-black-80 font-medium text-xs">
                    {t('contact.form.message_1')}
                  </span>
                  <span className="text-black-80 font-medium text-xs">
                    {t('contact.form.message_2')}
                  </span>
                  <span className="text-black-80 font-medium text-xs mt-2 md:mt-3">
                    {t('contact.form.message_3')}
                  </span>
                  <Divider />
                  <form
                    className="flex flex-col gap-4"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="flex flex-col md:flex-row gap-4 md:gap-3">
                      <Input
                        label={t('contact.form.name')}
                        isRequired
                        placeholder={t('contact.form.name')}
                        {...register('name', {
                          required: t('contact.form.error_message_required'),
                        })}
                        error={errors.name && (errors.name.message as string)}
                      />
                    </div>
                    <Input
                      label={t('contact.form.email')}
                      isRequired
                      placeholder={t('contact.form.email_placeholder')}
                      {...register('email', {
                        required: t('contact.form.error_message_required'),
                        pattern: {
                          value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: t('contact.form.error_message_wrong_email'),
                        },
                      })}
                      error={errors.email && (errors.email.message as string)}
                    />
                    <Textarea
                      label={t('contact.form.message')}
                      isRequired
                      placeholder={t('contact.form.message_placeholder')}
                      {...register('message', {
                        required: t('contact.form.error_message_required'),
                      })}
                      error={
                        errors.message && (errors.message.message as string)
                      }
                    />
                    <Checkbox
                      label={
                        <>
                          {t('contact.form.checkbox_label')}{' '}
                          <a
                            href={privacyPolicyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-100 underline font-semibold"
                          >
                            {`${t('contact.form.checkbox_policy')}*`}
                          </a>
                        </>
                      }
                      {...register('checkbox', {
                        required: t(
                          'contact.form.error_message_privacy_acceptance'
                        ),
                      })}
                      error={
                        errors.checkbox && (errors.checkbox.message as string)
                      }
                    />
                    <Button
                      className="text-base leading-20 max-s:py-3 py-5"
                      text={t('contact.form.send_button')}
                      type="submit"
                      icon={<ArrowRightIcon />}
                      disabled={isLoading}
                    />
                  </form>
                </div>
              </>
            )}
            {step === 'Success' && !isLoading && (
              <div className="flex flex-col w-full h-full justify-center min-h-[300px]">
                <StatusScreen
                  title={t('contact.form.success_title')}
                  message={t('contact.form.success_answer')}
                  type="success"
                />
              </div>
            )}
            {step === 'Error' && !isLoading && (
              <div className="flex flex-col w-full h-full justify-center min-h-[300px]">
                <StatusScreen
                  title={t('contact.form.error_title')}
                  message={t('contact.form.error_answer')}
                  type="error"
                />
              </div>
            )}
            {isLoading && (
              <div className="flex flex-col w-full h-full justify-center min-h-[300px]">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
