import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'utils/cn';

import { PolishFlagIcon, EnglishFlagIcon, ArrowDownIcon } from 'assets/icons';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const languageSwitcherRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        languageSwitcherRef.current &&
        !languageSwitcherRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageSwitcherRef]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <div
      ref={languageSwitcherRef}
      className="relative inline-block text-left mr-5 md:mr-[5.625rem] z-30"
    >
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center w-full py-2 bg-white text-xs font-semibold text-black"
          id="language-menu"
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={() => setIsOpen(!isOpen)}
        >
          {i18n.language === 'pl' ? (
            <PolishFlagIcon className="w-6 h-6 mr-2" />
          ) : (
            <EnglishFlagIcon className="w-6 h-6 mr-2" />
          )}
          {i18n.language.toUpperCase()}
          <ArrowDownIcon className="ml-1 max-sm:hidden" />
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute mt-2 rounded-[0.625rem] shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="language-menu"
        >
          <div className="py-1">
            <button
              className={cn(
                'w-full text-black px-3 py-2 text-xs font-semibold flex items-center',
                i18n.language === 'pl' && 'underline font-bold'
              )}
              role="menuitem"
              onClick={() => changeLanguage('pl')}
            >
              <PolishFlagIcon className="w-4 h-4 mr-2" />
              PL
            </button>
            <button
              className={cn(
                'w-full text-black px-3 py-2 text-xs font-semibold flex items-center',
                i18n.language === 'en' && 'underline font-bold'
              )}
              role="menuitem"
              onClick={() => changeLanguage('en')}
            >
              <EnglishFlagIcon className="w-4 h-4 mr-2" />
              EN
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
