import { Link } from 'react-router-dom';
import { ContactUsBanner, Tag, Tile, Button } from 'components';
import { Heading, Text } from 'components/Typography';
import { useLazyBackground } from 'hooks/useLazyBackground';
import { useTranslation } from 'react-i18next';

import { productMainBackground } from 'assets/images/backgrounds';

import { transmitter, receiver, relay } from 'assets/images/product';

import {
  DiveMaskIcon,
  HardwareIcon,
  AIChipIcon,
  SoftwareIcon,
  LiveLocalizationIcon,
  SosIcon,
  SafetyIcon,
  LogIcon,
} from 'assets/icons/product';

import { ArrowRightIcon, WaveIcon } from 'assets/icons';

const wavyFeatures = [
  {
    id: 1,
    icon: (
      <DiveMaskIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_knowledge_tile_title',
    description: 'product.why_us_knowledge_tile_description',
  },
  {
    id: 2,
    icon: (
      <HardwareIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_hardware_tile_title',
    description: 'product.why_us_hardware_tile_description',
  },
  {
    id: 3,
    icon: (
      <AIChipIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_ai_tile_title',
    description: 'product.why_us_ai_tile_description',
  },
  {
    id: 4,
    icon: (
      <SoftwareIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_software_tile_title',
    description: 'product.why_us_software_tile_description',
  },
  {
    id: 5,
    icon: (
      <LiveLocalizationIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_location_tile_title',
    description: 'product.why_us_location_tile_description',
  },
  {
    id: 6,
    icon: (
      <SosIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_sos_tile_title',
    description: 'product.why_us_sos_tile_description',
  },
  {
    id: 7,
    icon: (
      <SafetyIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_safety_tile_title',
    description: 'product.why_us_safety_tile_description',
  },
  {
    id: 8,
    icon: (
      <LogIcon className="w-[50px] h-[50px] desktop:w-[70px] desktop:h-[70px]" />
    ),
    title: 'product.why_us_log_tile_title',
    description: 'product.why_us_log_tile_description',
  },
];

export const Product = () => {
  const { t } = useTranslation();
  useLazyBackground('bg-cover', productMainBackground);

  return (
    <div
      style={{
        backgroundImage: `url(${productMainBackground}`,
      }}
      className="overflow-x-hidden bg-cover bg-no-repeat bg-center"
    >
      <div className="flex flex-col items-center container mx-auto pt-6 px-6">
        <Tag
          text={t('product.tag')}
          className="mb-3 md:mb-6"
          icon={<WaveIcon />}
        />
        <Heading as="h1" className="mb-4 md:mb-8 uppercase text-center">
          {t('product.title')}
        </Heading>
        <Text className="text-black text-center mb-4 md:mb-16">
          {t('product.description')}
        </Text>
        <div className="mb-8 md:mb-16">
          <div className="flex flex-col md:flex-row justify-center items-center w-full gap-10 md:gap-16 mb-5">
            <img
              className="w-[280px] s:w-[350px] md:w-[480px]"
              src={receiver}
              alt={t('product.receiver_title')}
            />
            <div className="flex flex-col gap-2 md:gap-4">
              <Text className="!font-semibold !text-2xl !text-black">
                {t('product.receiver_title')}
              </Text>
              <Text>{t('product.receiver_description')}</Text>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row justify-center items-center w-full gap-10 md:gap-16 mb-5">
            <div className="flex flex-col gap-2 md:gap-4">
              <Text className="!font-semibold !text-2xl !text-black">
                {t('product.relay_title')}
              </Text>
              <Text>{t('product.relay_description')}</Text>
            </div>
            <img
              className="w-[280px] s:w-[350px] md:w-[480px]"
              src={relay}
              alt={t('product.relay_title')}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center w-full gap-10 md:gap-16 mb-5">
            <img
              className="w-[280px] s:w-[350px] md:w-[480px]"
              src={transmitter}
              alt={t('product.transmitter_title')}
            />
            <div className="flex flex-col gap-2 md:gap-4">
              <Text className="!font-semibold !text-2xl !text-black">
                {t('product.transmitter_title')}
              </Text>
              <Text>{t('product.transmitter_description')}</Text>
            </div>
          </div>
        </div>
        <Tag text={t('product.why_us_tag')} className="mb-3 md:mb-6" />
        <Heading as="h2" className="mb-4 md:mb-8 uppercase text-center">
          {t('product.why_us_title')}
        </Heading>

        <div className="flex flex-wrap gap-2 md:gap-4 justify-center">
          {wavyFeatures.map(({ icon, id, description, title }) => (
            <Tile
              key={id}
              icon={icon}
              title={t(title)}
              description={t(description)}
            />
          ))}
          <Link
            className="hidden md:block mt-6 md:mt-8 mb-8 md:mb-16"
            to="/contact"
          >
            <Button
              text={t('product.contact_us_redirect_button')}
              icon={<ArrowRightIcon />}
            />
          </Link>
        </div>
      </div>
      <div className="max-sm:mt-6 max-sm:pt-6">
        <section className="flex items-end container mx-auto pb-6 mt-24">
          <ContactUsBanner />
        </section>
      </div>
    </div>
  );
};
