import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

declare const require: {
  context: (
    path: string,
    deep?: boolean,
    filter?: RegExp
  ) => {
    keys: () => string[];
    (key: string): string;
  };
};

const images = require.context(
  'assets/images/partners',
  false,
  /\.(webp|png|jpg|jpeg|svg)$/
);

interface Partner {
  id: number;
  logo: string;
  name: string;
}

const partners: Partner[] = images.keys().map((imagePath, index) => ({
  id: index,
  logo: images(imagePath),
  name: imagePath.replace('./', '').split('.')[0],
}));

export const PartnerCarousel = () => {
  return (
    <div className="container mx-auto">
      <Splide
        options={{
          type: 'loop',
          autoWidth: true,
          pagination: false,
          perPage: 3,
          height: '150px',
          breakpoints: {
            640: { perPage: 1 },
            768: { perPage: 2 },
            1024: { perPage: 4 },
          },
          gap: '1rem',
        }}
      >
        {partners.map((partner) => (
          <SplideSlide key={partner.id}>
            <img
              src={partner.logo}
              alt={partner.name}
              className="w-full h-[150px] object-contain"
              loading="lazy"
              height={150}
              width="auto"
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
